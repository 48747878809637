<template>
  <div class="setPower">
    <h3>操作权限</h3>
    <el-form :model="formData" ref="form" :rules="rules" label-position="top">
      <el-form-item label="" prop="" class="tree-box">
        <el-tree
          :data="treeData"
          show-checkbox
          ref="tree"
          node-key="id"
          :props="defaultProps"
          :default-checked-keys="defaultKeys"
        >
        </el-tree>
      </el-form-item>
    </el-form>
    <h3>关联客户</h3>
    <div class="customer">
      <div class="customer-search">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="请输入公司名称"
          v-model="search.enterpriseName"
          @keyup.enter.native="searchFn"
        />
        <el-button class="hy-btn" @click="searchFn">查询</el-button>
      </div>
      <el-transfer
        style="margin-top: 20px"
        :titles="titles"
        ref="usertransfer"
        v-model="checkCustomer"
        :props="{
          key: 'customerId',
          label: 'customerName',
        }"
        :data="enterpriseCustomer"
        @right-check-change="rightChange"
        @change="checkChange"
      >
        <div slot-scope="{ option }">
          <span :title="option.customerName">{{ option.customerName }}</span>
          <span>{{ option.customerTelphone }}</span>
          <el-button
            type="text"
            v-if="hasCurrentCustomer(option)"
            @click.stop="handelRemoveCustomer(option)"
            >删除</el-button
          >
        </div>
      </el-transfer>
      <div>若不选择关联客户则子账号初始无订单相关数据信息</div>
    </div>
    <div class="btn-wrap">
      <el-button @click="submit" class="hy-btn" :loading="submiting"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import { reqUserMenuTree, reqEditUserMenuTree } from "@/api/hy";
import {
  getAccountCustomers,
  addCustomer,
  deleteCustomer,
} from "@/api/userCenters.js";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      formData: {},
      rules: {},
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      defaultKeys: [],
      submiting: false,
      search: {
        enterpriseName: "",
      },
      enterpriseId: "",
      userId: "",
      keepCustomer: [],
      enterpriseCustomer: [],
      checkCustomer: [],
      titles: ["客户关系管理表", "已选择用户"],
      historyCustomer: [],
    };
  },
  computed: {
    hasCurrentCustomer() {
      return (val) => {
        return this.checkCustomer.indexOf(val.customerId) !== -1;
      };
    },
  },
  async mounted() {
    let enterpriseId = Vue.ls.get(types.enterpriseId);
    let userId = this.$route.query.userId;
    this.enterpriseId = enterpriseId;
    this.userId = userId;
    this.getDetail({ userId: this.$route.query.userId });
    await this.initCustomer();
    await this.searchFn();
  },
  methods: {
    handelRemoveCustomer(val) {
      if (
        this.historyCustomer.find((item) => {
          return item.customerId === val.customerId;
        })
      ) {
        deleteCustomer({ relationshipIdList: [val.relationshipId] }).then(
          (res) => {
            if (res.code == 0) {
              this.checkCustomer.splice(
                this.checkCustomer.findIndex((item) => {
                  return item.customerId === val.customerId;
                }),
                1
              );
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.message);
            }
          }
        );
      } else {
        this.checkCustomer.splice(
          this.checkCustomer.findIndex((item) => {
            return item.customerId === val.customerId;
          }),
          1
        );
      }
    },
    addCustomer() {
      return new Promise((resolve, reject) => {
        let arr = [];
        this.checkCustomer.forEach((item) => {
          if (
            !this.historyCustomer.find((i) => {
              return i.customerId == item;
            })
          ) {
            arr.push(item);
          }
        });
        if (arr.length) {
          addCustomer({
            userId: this.userId,
            customerIdList: arr,
          }).then((res) => {
            if (res.code == 0) {
              resolve();
            } else {
              this.$message.error(res.message);
              reject();
            }
          });
        }
      });
    },
    rightChange() {
      console.log(this.checkCustomer);
      if (this.$refs.usertransfer) {
        this.$nextTick(() => {
          this.$refs.usertransfer.rightChecked = [];
        });
      }
    },
    checkChange(e, type, list) {
      if (type === "right") {
        let currentList = [];
        list.forEach((item) => {
          currentList = [
            ...currentList,
            this.enterpriseCustomer.find((i) => i.customerId === item),
          ];
        });
        this.keepCustomer = [...this.keepCustomer, ...currentList];
      }
    },
    async initCustomer() {
      await this.getUserCustomer();
    },
    getUserCustomer() {
      let config = {
        userId: this.userId,
      };
      return new Promise((resolve, reject) => {
        getAccountCustomers(config).then((res) => {
          if (res.code == 0) {
            this.keepCustomer =
              res.data?.map((item) => {
                let obj = { ...item };
                delete obj["updateTime"];
                return obj;
              }) || [];
            this.checkCustomer = this.keepCustomer?.map(
              (item) => item.customerId
            );
            this.historyCustomer = this.keepCustomer;
            resolve();
          } else {
            this.$message.error(res.message);
            reject();
          }
        });
      });
    },
    searchFn() {
      let config = {
        ...this.search,
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        ifExcludeBound: true,
      };
      return new Promise((resolve, reject) => {
        getAccountCustomers(config).then((res) => {
          if (res.code == 0) {
            let arr = [];
            let enterpriseCustomer =
              res.data?.map((item) => {
                let obj = { ...item };
                delete obj["updateTime"];
                return obj;
              }) || [];
            enterpriseCustomer.forEach((item) => {
              if (
                !this.keepCustomer.find((i) => {
                  return i.customerId === item.customerId;
                })
              ) {
                arr.push(item);
              }
            });
            let current = arr.concat(this.keepCustomer) || [];
            this.enterpriseCustomer = [
              ...new Set(current.map(JSON.stringify)),
            ].map(JSON.parse);
            console.log(this.enterpriseCustomer);
            resolve();
          } else {
            this.$message.error(res.message);
            reject();
          }
        });
      });
    },
    getDetail(data) {
      reqUserMenuTree(data).then((ret) => {
        if (ret.code == 0) {
          this.treeData = ret.data.children;
          this.setDefaultKeys(this.treeData);
        }
      });
    },
    setDefaultKeys(list) {
      let keyList = [];
      let formatTree = function (list) {
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.privilege == 0) {
            keyList.push(item.id);
          }
          if (item.children && item.children.length > 0) {
            formatTree(item.children);
          }
        }
      };
      formatTree(list);
      this.defaultKeys = keyList;
    },
    getMenuList() {
      let nlist = [...this.$refs.tree.getCheckedKeys()];
      nlist = [...new Set(nlist)];
      return nlist;
    },
    submit() {
      if (this.submiting) return;
      this.submiting = true;
      let data = {
        userId: this.$route.query.userId,
        menuIdList: this.getMenuList(),
      };
      this.addCustomer();
      // return;
      reqEditUserMenuTree(data)
        .then((ret) => {
          console.log(ret);
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
.customer {
  .el-transfer {
    .el-transfer-panel {
      width: 360px;
      .el-transfer-panel__body {
        .el-checkbox__label {
          & > div {
            // display: flex;
            // align-items: center;
            & > span {
              display: inline-block;
              width: 140px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &:nth-child(2){
                // margin-left: 164px;
              }
            }
          }
        }
      }
      &:nth-of-type(3) {
        * {
          color: #606266;
        }
        .el-transfer-panel__header {
          .el-checkbox__input {
            display: none;
          }
          .el-checkbox__label {
            padding-left: 0;
          }
          .el-checkbox__label {
            span {
              display: none;
            }
          }
        }
        .el-transfer-panel__body {
          // .el-transfer-panel__item {
          //   width: 100%;
          // }
          .el-checkbox__input {
            display: none;
          }
          .el-checkbox__label {
            padding-left: 0;
            & > div {
              .el-button {
                display: inline-block;
                vertical-align: text-bottom;
                span {
                  color: #f56c6c;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.setPower {
  width: 1200px;
  margin: 14px auto;
  background: white;
  &::before,
  &::after {
    display: table;
    content: "";
  }
  h3 {
    font-size: 18px;
    padding: 14px 20px;
    background: #f7f7f7;
    border: 1px solid #e5e5e5;
  }
  .customer {
    padding: 20px;
    .customer-search {
      display: flex;
      align-items: center;
      .el-input {
        width: 300px;
      }
    }
  }
}
.el-form {
  padding: 20px;
}
.btn-wrap {
  text-align: right;
  margin: 20px;
}
</style>
